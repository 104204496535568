import React, { useState } from "react";
import "./BorgesReviewForm.css";
import Question from "./Question";
import UserService from "../services/Register";

const BorgesReviewForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rating, setRating] = useState(0);
  const [qa, setqa] = useState({});
  const [errors, setErrors] = useState({});
  const [apiData,setApiData] = useState({});
  const [satisfactionLevel,setSatisfactionLevel] = useState();
  const [feedback,setFeedback] = useState();
  const [recommendation,setRecommendation] = useState();

  const requiredQuestions = [
    "virginOil",
    "purpose",
    "oliveOilUse",
    "foodTaste",
    "BuyProduct"
  ];

  const handleAnswer = (question, answer) => {
    const updatedQA = { ...qa, [question]: answer };
    setqa(updatedQA);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!name.trim()) newErrors.name = "Name is required.";
    if (!email.trim()) newErrors.email = "Enter a Valid email";
    if (!rating) newErrors.rating = "Rating is required.";
    if (!satisfactionLevel ) newErrors.satisfactionLevel = "Satisfaction Level is required";
    if (!recommendation) newErrors.recommendation = "Recommendation is required";
    
    requiredQuestions.forEach((key) => {
      if (!qa[key]) newErrors[key] = `${key.replace(/([A-Z])/g, " $1")} is required.`;
    });
    console.log(requiredQuestions);
    console.log(newErrors)
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const makeApiResponse = () =>{
    apiData["name"] = name;
    apiData["email"] = email;
    apiData["rating"] = rating;
    apiData["satisfactionLevel"] = satisfactionLevel;
    apiData["feedback"] = feedback;
    apiData["recommendation"] = recommendation;
    apiData["qa"]=JSON.stringify(qa);
    return apiData;
  }
  const handleSubmit = async(e) => {
    e.preventDefault();
    try{
    if (validateForm()) {
      const data = makeApiResponse();
      console.log(data);
      const apiResponse  = await UserService.borgesFeedback(data);
      if(apiResponse.data.status == 1)
      {
        console.log("submitted")
        alert("Form Submitted");
        setName("");
        setEmail("");
        setRating(0);
        setqa({});
        setErrors({});
        setApiData({});
        setSatisfactionLevel(null);
        setFeedback("");
        setRecommendation(null);
        window.location.reload();

      }
      else 
      {
        console.log("error");
        alert("Try Using Different Email");
      }
    } else {
      alert("Please fill out all required fields.");
    }
  }
  catch(error){
    // alert("Something went wrong. Please try again later. ");
  console.error("An error occurred: ",error);    
  }
};

  return (
    <form className="review-form" onSubmit={handleSubmit}>
      <div className="form-logo">
        <img src={"/assets/borges-logo.png"} alt="Company Logo" />
      </div>

      <div className="form-group">
        <label htmlFor="name">Your Name</label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.name && <p className="error" style={{color:"red"}}>{errors.name}</p>}
      </div>

      {/* Email Field */}
      <div className="form-group">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.email && <p className="error" style={{color:"red"}}>{errors.email}</p>}
      </div>

      {/* Questions */}
      <Question
        question="Do you Use Extra Virgin Olive oil at home?"
        options={["Yes", "No"]}
        type="radio"
        onAnswer={(answer) => handleAnswer("virginOil", answer)}
      />
      {errors.virginOil && <p className="error" style={{color:"red"}}>{errors.virginOil}</p>}

      <Question
        question="For what purpose do you use extra Virgin Olive oil?"
        options={[
          "Bread Toast",
          "Salad Dressing",
          "Sautéing ",
          "Other",
        ]}
        type="radio"
        onAnswer={(answer) => handleAnswer("purpose", answer)}
      />
      {errors.purpose && <p className="error" style={{color:"red"}}>{errors.purpose}</p>}

      <Question
        question="Did you know that olive oil can be used for everyday cooking before you met us today?"
        options={[
          "Yes",
          "No"
        ]}
        type="radio"
        onAnswer={(answer) => handleAnswer("oliveOilUse", answer)}
      />
      {errors.oliveOilUse && <p className="error" style={{color:"red"}}>{errors.oliveOilUse}</p>}

      <Question
        question="How did you find the flavour of the food you tasted"
        options={["Good","Bad", "Other"]}
        type="radio"
        onAnswer={(answer) => handleAnswer("foodTaste", answer)}
      />
      {errors.foodTaste && <p className="error" style={{color:"red"}}>{errors.foodTaste}</p>}

      {/* <Question
        question="Select the ones that you agree with:"
        options={[
          "Taste was good",
          "Flavour was there",
          "I didn't like it",
          "I loved it",
          "Flavour was very less. I couldn't feel it.",
          "Other",
        ]}
        type="checkbox"
        onAnswer={(answer) => handleAnswer("TasteFeedback", answer)}
      />
      {errors.TasteFeedback && <p className="error" style={{color:"red"}}>{errors.TasteFeedback}</p>} */}

      <Question
        question="Will you buy this product?"
        options={["Yes", "No"]}
        type="radio"
        onAnswer={(answer) => handleAnswer("BuyProduct", answer)}
      />
      {errors.BuyProduct && <p className="error" style={{color:"red"}}>{errors.BuyProduct}</p>}

      {/* Rating Field */}
      <div className="form-group">
        <label>Overall Rating</label>
        <div className="rating-stars">
          {[1, 2, 3, 4, 5].map((star) => (
            <span
              key={star}
              onClick={() => setRating(star)}
              style={{ color: star <= rating ? "gold" : "gray", cursor: "pointer" }}
            >
              ★
            </span>
          ))}
        </div>
        {errors.rating && <p className="error" style={{color:"red"}}>{errors.rating}</p>}
      </div>
      
      <div className="form-group">
        <label>Satisfaction Level</label>
        <div className="satisfaction-level">
          {[1, 2, 3, 4, 5].map((level) => (
            <span
              
              key={level}
              onClick={() => {setSatisfactionLevel(level);}}
              className={`level ${
                level <= satisfactionLevel ? `level-${level}` : "inactive"
              }`}
            >
              {["Unhappy", "Neutral", "Satisfied", "Happy", "Very Happy"][level-1]}
            </span>
          ))}
        </div>
        {errors.satisfactionLevel && <p className="error" style={{color:"red"}}>{errors.satisfactionLevel}</p>}

      </div>
      <div className="form-group">
        <label htmlFor="feedback">Feedback</label>
        <textarea
          id="feedback"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </div>
      <div className="recommend">
        <label className="recommend-que">Would you recommend us?</label>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              checked={recommendation === "yes"}
              onChange={() => setRecommendation("yes")}
            />
            Yes
          </label>
          <label>
            <input
              type="radio"
              checked={recommendation === "no"}
              onChange={() => setRecommendation("no")}
            />
            No
          </label>
        </div>
      </div>
      {errors.recommendation && <p className="error" style={{color:"red"}}>{errors.recommendation}</p>}
            

      <button type="submit" className="submit-button">
        Submit Review
      </button>
    </form>
  );
};

export default BorgesReviewForm;
